/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, CookieManager, Swiper) => {
        /*
        |
        | Constants
        |-----------
        */
        const
            $body = $('body'),
            $cookieBanner = $('.cookie-banner'),
            $cookieClose = $cookieBanner.find('.item-close'),
            $cookieAccept = $cookieBanner.find('.item-accept'),
            $cookieRefuse = $cookieBanner.find('.item-refuse')
            ;


        /*
        |
        | Cookie Manager
        |-----------------
        */
        new CookieManager($cookieBanner, {
            name: 'adveris_cookie_use',
            duration: 30,
            buttons: {
                accept: $cookieAccept,
                refuse: $cookieRefuse,
                close: $cookieClose
            },
            onAccept: () => {
                console.log('accpeted')
            },
            onRefuse: () => {
                console.log('refused')
            }
        });

        $body.on('loaderEnd', () => console.log('ended'))


        /* Home slider news */
        var swiper = new Swiper('#slider-home.swiper-container', {
            slidesPerView: 3,
            spaceBetween: 30,
            navigation: {
                prevEl: '.swiper-btn-prev',
                nextEl: '.swiper-btn-next'
            },
            breakpoints: {
                992: {
                    centeredSlides: true,
                    slidesPerView: 1,
                    spaceBetween: 10,
                    allowSlidePrev: true,
                    allowSlideNext: true,
                }
            },
        });


        /* Home slider news */
        var swiper = new Swiper('#slider-flexi.swiper-container', {
            slidesPerView: 2.5,
            spaceBetween: 30,
            loop: true,
            centeredSlides: true,
            navigation: {
                prevEl: '.swiper-btn-prev',
                nextEl: '.swiper-btn-next'
            },
            breakpoints: {
                992: {
                    centeredSlides: true,
                    slidesPerView: 1,
                    spaceBetween: 10,
                    allowSlidePrev: true,
                    allowSlideNext: true,
                }
            },
        });


        /* Galerie photos articles */
        var swiper = new Swiper('#slider-crcc.swiper-container', {
            slidesPerView: 1,
            loop: true,
            grabCursor: true,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            navigation: {
                prevEl: '.swiper-btn-prev',
                nextEl: '.swiper-btn-next'
            },
        });

        /* Galerie photos  */
        var swiper = new Swiper('#swipermenu.swiper-container', {
            slidesPerView: 3,
            grabCursor: true,
            centeredSlides: true,
            navigation: {
                prevEl: '.swiper-btn-prev',
                nextEl: '.swiper-btn-next'
            },
            breakpoints: {
                768: {
                    slidesPerView: 1,
                    spaceBetween: 15
                }
            },
        });

        /* Galerie photos articles */
        var swiper = new Swiper('#galerie-news.swiper-container', {
            slidesPerView: 3,
            loop: true,
            effect: 'coverflow',
            grabCursor: true,
            centeredSlides: true,
            coverflowEffect: {
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: true,
            },
            breakpoints: {
                992: {
                    slidesPerView: 1,
                    spaceBetween: 15
                }
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
        });


        $("#menu-flexible a[href*='#']:not([href='#'])").click(function () {
            if (
                location.hostname == this.hostname
                && this.pathname.replace(/^\//, "") == location.pathname.replace(/^\//, "")
            ) {
                var anchor = $(this.hash);
                anchor = anchor.length ? anchor : $("[name=" + this.hash.slice(1) + "]");
                if (anchor.length) {
                    $("html, body").animate({ scrollTop: anchor.offset().top }, 1500);
                }
            }
        });

        jQuery(window).scroll(function () {
            if ($(this).scrollTop() > 200) {
                $('.scrollToTop').fadeIn();
            } else {
                $('.scrollToTop').fadeOut();
            }
        });
        jQuery('.scrollToTop').click(function () {
            $('html, body').animate({ scrollTop: 0 }, 800);
            return false;
        });


        // Change tab class and display content
        $('.tabs-nav li').on('click', function (event) {
            event.preventDefault();

            $('.tabs-nav li').removeClass('tab-active');

            $(this).attr('class')

            $(this).addClass('tab-active');

            $('.tabs-stage .tabcontent').hide();
            $($(this).children('a').attr('href')).show();
        });

        $('.tabs-nav a:first').trigger('click'); // Default



        // if (jQuery(this).scrollTop() > 450) {
        //     jQuery('#swipermenu').addClass('active');
        // } else {
        //     jQuery('#swipermenu').removeClass('active');
        // }
        // jQuery(window).scroll(function () {
        //     if (jQuery(this).scrollTop() > 450) {
        //         jQuery('#swipermenu').addClass('active');
        //     } else {
        //         jQuery('#swipermenu').removeClass('active');
        //     }
        // });


        jQuery('.btn-menu').click(function () {
            jQuery('body').find('.header-container').toggleClass('activefix');
        });

        /*
        |
        | Submenu
        |-----------
        */
        if ($(window).width() > 1200) {
            $('.nav-drop', this).not('.in .nav-drop').stop(true, true).slideUp("10");
            $(".menu-item-has-children").hover(
                function () {
                    $('.nav-drop', this).not('.in .nav-drop').stop(true, true).delay(100).slideDown("10");
                    $(this).toggleClass('open');
                },
                function () {
                    $('.nav-drop', this).not('.in .nav-drop').stop(true, true).delay(100).slideUp("10");
                    $(this).toggleClass('open');
                }
            );
        }



        document.addEventListener('DOMContentLoaded', () => {
            // This is the bare minimum JavaScript. You can opt to pass no arguments to setup.
            const player = new Plyr('#player');

            // Expose
            window.player = player;

            player.on('play', event => {
                $('.a-detail-d').hide();
                $('.a-detail-n').hide();
            });

            player.on('pause', event => {
                $('.a-detail-d').show();
                $('.a-detail-n').show();
            });


        });

        let xhr = null;

        /*
        |
        | Clique sur les filtres
        |-------------------------
        */
        $('.filter-item').on('click', function () {

            $('.filter-item').each(function () {
                $(this).removeClass('active');
            })

            $(this).addClass('active');

            var taxoId = $(this).data().taxoid
            loadProductsAjax(taxoId, 'post');
        })

        /*
        |
        | Filtre en Ajax
        |------------------
        */
        function loadProductsAjax(taxo, post) {
            if (xhr !== null) {
                xhr.abort()
            }

            //Ajoute le loader
            document.querySelector('.loading').style.display = "block";

            document.querySelector('#a-la-une').innerHTML = "";

            const route = `/ajax/news/category/${post}/`;

            xhr = $.ajax({
                url: route,
                type: 'GET',
                dataType: 'json',
                data: {
                    'taxoId': taxo,
                    'post': post
                },
                success: (response, status) => {


                    //Rempli le DOM
                    document.querySelector('#a-la-une').innerHTML = response;
                    document.querySelector('#a-la-une').classList.add('ajax-on');

                    document.querySelector('.loading').style.display = "none";

                    xhr = null;
                },
                error: (response, status, error) => {
                    console.log(response, status, error);
                }
            })
        }


        if ($('#pagename-nos-evenements')) {
            var items = $('.this-time').find('.item-card');
            var $btn = $('.this-time').find('.more-post-card');

            if (items.length > 4) {
                $('.no-events').hide();
                $btn.show()
            }
            else if (items.length < 5 && items.length > 0) {
                $('.no-events').hide();
                $btn.hide()
            }
            else {
                $('.no-events').show();
                $btn.hide()
            }
        }




        /*
        |
        | Tracking
        |-----------------
        */
        $('body').on('click', '[data-type]', function (event) {
            let $el = $(this),
                name = $el.data('name'),
                page = $el.data('page'),
                type = $el.data('type');
            console.log(name, page, type)
            dataLayer.push({
                'event': 'customClick',
                'name': name,
                'page': page,
                'type': type
            });
        });

        /*
        |
        | Banner flash infos
        |-----------------
        */
        const bannerFlash = document.querySelector('.banner-infos');
        const bannerFlashClose = document.querySelector('.banner-infos .btn-close');

        if (bannerFlash) {
            bannerFlashClose.addEventListener('click', function () {
                $(bannerFlash).slideUp();
            })
        }

        /*
        |
        | Home video
        |-----------------
        */
        $('.video-container').click(function () {
            var videoWrapper = $(this).find('.video-wrapper');
            if (videoWrapper.length > 0) {
                var videoIframe = videoWrapper.find('iframe');
                if (videoIframe.length > 0) {
                    $(this).closest('.row').addClass('video-active');
                    videoWrapper.show();
                }
            }
        });

        /*
        |
        | Menu acces direct
        |-----------------
        */
        $(function () {
            $("#adirect").click(function () {
                $("#menu-adirect").addClass('menu-open');
            });

            $(".close-ad").click(function () {
                $("#menu-adirect").removeClass('menu-open');
            });
        });

    }
}
