/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, Menu, Kira, TweenMax, ScrollMagic) => {
        /*
        |
        | Constants
        |-----------
        */
        const
            $body = $('body'),
            $pageLoader = $('.page-loader'),
            scrollAreas = document.querySelectorAll('.scroll-area'),
            $menuWrapper = $('#mobile-menu'),
            $menuButton = $('#header .btn-menu'),
            $menuBars = $menuButton.find('.item-burger > span'),
            $menuClose = $menuWrapper.find('.item-close'),
            $menuLi = $menuWrapper.find('ul li')
            ;


        /*
        |
        | Scroll Areas
        |---------------
        */
        Array.prototype.forEach.call(scrollAreas, element => {
            Scrollbar.init(element);
        });


        /*
        |
        | Loader
        |---------
        */
        const loaderTl = new TimelineMax({ paused: true, onComplete: () => $pageLoader.remove() });

        loaderTl.to($pageLoader.find('.item-loadbar-inner'), 0.4, { scaleX: 1, ease: Power0.easeNone }, 'start')
        loaderTl.to($pageLoader.find('.item-content'), 0.8, { autoAlpha: 0, ease: Power1.easeOut }, '-=0')
        loaderTl.addCallback(() => { app.dispachEvent($body, 'loaderEnd'); })
        loaderTl.to($pageLoader, 0.8, { autoAlpha: 0, ease: Power1.easeOut }, '-=0')

        $(window).on('load', function () {
            loaderTl.play();
        });


        /*
        |
        | Menu
        |-------
        */
        const menu = new Menu($menuWrapper, $menuButton, {
            reverseTimeScale: 2
        });

        menu.menuTimeline
            .to($menuBars.eq(1), 0.3, { autoAlpha: 0 }, 'start')
            .to($menuBars.eq(0), 0.5, { x: 0, y: 8, rotation: 45, ease: Power1.easeOut }, 'start')
            .to($menuBars.eq(2), 0.5, { x: 0, y: -8, rotation: -45, ease: Power1.easeOut }, 'start')
            .from($menuWrapper, 0.5, { autoAlpha: 0, ease: Power1.easeOut }, 'start+=0.3')
            .staggerFrom($menuLi, 0.6, { autoAlpha: 0, x: 20, ease: Power1.easeOut }, '0.1', '+=0')

        menu.init();

        /*
        |
        | Kira
        |-------
        */
        const kira = new Kira(ScrollMagic, {
            debug: false,
            loadEvent: {
                domElement: $body,
                eventName: 'loaderEnd'
            },
            optimize: true,
            options: {
                start: '-=0.6',
                triggerHook: 'onEnter'
            }
        });

        /*
        | Anime hero
        |-----------------------
        */
        const myTimeline = new TimelineMax();
        myTimeline.delay(2);
        TweenMax.fromTo('.left-col', 1, { top: '135px' }, { top: '115px', ease: Bounce.easeOut, delay: 2 });
        TweenMax.fromTo('.right-col', 1, { top: '180px' }, { top: '270px', ease: Bounce.easeOut, delay: 2 });


        /*
        | fadeInUp
        |-----------------------
        */
        kira.add('fadeInUp', ($item, timeline, start) => {
            timeline.from($item, 0.8, { y: 30, autoAlpha: 0, ease: Power1.easeOut }, start)
        });

        /*
        | fadeInUp.parallax.sm
        |-----------------------
        */
        kira.add('fadeInUp.parallax.sm', ($item, timeline, start) => {
            timeline.from($item, 0.8, { y: 100, autoAlpha: 0, ease: Sine.easeOut }, start)
        });

        /*
        | fadeInUp.parallax
        |--------------------
        */
        kira.add('fadeInUp.parallax', ($item, timeline, start) => {
            timeline.from($item, 1, { y: 80, autoAlpha: 0, ease: Power1.easeOut }, start)
        });

        /*
        | fadeInUp.parallax.sm
        |-----------------------
        */
        kira.add('fadeInLeft.stagger.sm', ($item, timeline, start) => {
            timeline.staggerFrom($item.find('[data-stagger-item]'), 0.6, { autoAlpha: 0, x: 20, ease: Power1.easeOut }, '0.1', start)
        });

        /*
        | fadeInUp.parallax
        |--------------------
        */
        kira.add('scaleX', ($item, timeline, start) => {
            timeline.from($item, 1, { scaleX: 0, transformOrigin: 'left top', ease: Expo.easeInOut }, start)
        });

        kira.init();
    }
}
